import * as tagActions from 'spa/action_creators/tags'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
} from 'react-intl'
import { DropdownPopover } from 'spa/components/filters/dropdown_popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Heading } from 'spa/components/typography'
import { isMobileSelector } from '../../../selectors/device'
import { Knotch } from '../../knotch'
import { Link } from '../../link'
import { orderByOptions } from 'spa/shared/utils'
import { SearchBox } from 'spa/components/search_box'
import { seoMessages } from '../../app_wrapper/localizedMessages'
import { SORT_OPTIONS } from 'spa/components/filters'
import { TextStyle } from '../../typography'
import { TogglePanel } from 'spa/components/toggle_panel'
import { withRouter } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const localizeMessages = defineMessages({
  placeholder: {
    id: 'djcity.common.genre.search.placeholder',
    defaultMessage: 'Search genre...',
  },
  popularity: {
    id: 'djcity.common.genre.search.popularity',
    defaultMessage: 'Popularity',
  },
  asc: {
    id: 'djcity.common.genre.search.asc',
    defaultMessage: 'A-Z',
  },
  actionLink1: {
    id: 'djcity.common.genre.action.1.link',
    defaultMessage: 'hip-hop',
  },
  actionLink2: {
    id: 'djcity.common.genre.action.2.link',
    defaultMessage: 'djcity-exclusive',
  },
  actionLink3: {
    id: 'djcity.common.genre.action.3.link',
    defaultMessage: 'latin',
  },
  actionLink4: {
    id: 'djcity.common.genre.action.4.link',
    defaultMessage: 'african',
  },
  actionLink5: {
    id: 'djcity.common.genre.action.5.link',
    defaultMessage: 'tech-house',
  },
})

export class TagSearch extends React.PureComponent {
  static propTypes = {
    fetchTags: PropTypes.func.isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    isLoadingTags: PropTypes.bool,
    isMobile: PropTypes.bool,
    tags: PropTypes.array,
  }

  state = {
    searchTerm: '',
    sortBy: 5,
  }

  componentDidMount() {
    const { fetchTags, tags, isLoadingTags } = this.props

    if (!tags && !isLoadingTags) {
      fetchTags()
    }
  }

  handleChangeSorting = sortBy => {
    this.setState({ sortBy })
  }

  handleSearchChange = e => {
    this.setState({ searchTerm: e.target.value })
  }

  handleClear = () => {
    this.setState({ searchTerm: '' })
  }

  render() {
    const { tags, intl, isMobile } = this.props
    const { searchTerm, sortBy } = this.state
    let filteredTags = tags
      ? tags.filter(
          tag =>
            tag.name &&
            tag.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
        )
      : []
    if (filteredTags.length && sortBy !== SORT_OPTIONS.POPULARITY) {
      const { mode: sortMode } = orderByOptions[sortBy - 1]
      filteredTags = orderBy(filteredTags, 'name', [sortMode])
    }

    return (
      <div className={styles.base}>
        <div className={styles.header}>
          <TextStyle uppercase variant="extra-bold">
            <FormattedMessage
              defaultMessage="GENRES"
              id="djcity.common.genre.search.uptitle"
            />
          </TextStyle>
          <div className={styles.headingWrapper}>
            <Heading headingVariant="disguisedH2">
              <FormattedMessage
                defaultMessage="ALL GENRES"
                id="djcity.common.genre.search.title"
              />
            </Heading>
            {!isMobile && (
              <div>
                <TogglePanel
                  items={[
                    {
                      label: intl.formatMessage(localizeMessages.popularity),
                      value: SORT_OPTIONS.POPULARITY,
                    },
                    {
                      label: intl.formatMessage(localizeMessages.asc),
                      value: SORT_OPTIONS.TITLE_ASC,
                    },
                  ]}
                  onChange={this.handleChangeSorting}
                  value={sortBy}
                />
              </div>
            )}
          </div>
          <Heading headingVariant="disguisedH1">
            {intl.formatMessage(seoMessages.genresH1)}
          </Heading>
          <Knotch className={styles.knotch} size="big" />
        </div>
        <div className={styles.actionButtonsWrapper}>
          <div className={styles.actionButtons}>
            <Link
              to={`/genre/${intl.formatMessage(localizeMessages.actionLink1)}`}
            >
              <FormattedHTMLMessage
                defaultMessage="Hip Hop"
                id="djcity.common.genre.action.1.title"
              />
            </Link>
            <Link
              to={`/genre/${intl.formatMessage(localizeMessages.actionLink2)}`}
            >
              <FormattedHTMLMessage
                defaultMessage="Djcity Exclusive"
                id="djcity.common.genre.action.2.title"
              />
            </Link>
            <Link
              to={`/genre/${intl.formatMessage(localizeMessages.actionLink3)}`}
            >
              <FormattedHTMLMessage
                defaultMessage="Latin"
                id="djcity.common.genre.action.3.title"
              />
            </Link>
            <Link
              to={`/genre/${intl.formatMessage(localizeMessages.actionLink4)}`}
            >
              <FormattedHTMLMessage
                defaultMessage="African"
                id="djcity.common.genre.action.4.title"
              />
            </Link>
            <Link
              to={`/genre/${intl.formatMessage(localizeMessages.actionLink5)}`}
            >
              <FormattedHTMLMessage
                defaultMessage="Tech House"
                id="djcity.common.genre.action.5.title"
              />
            </Link>
          </div>
        </div>
        <SearchBox
          className={styles.tagSearch}
          onChange={this.handleSearchChange}
          placeholder={intl.formatMessage(localizeMessages.placeholder)}
          reset={isMobile && this.handleClear}
          value={searchTerm}
        />
        {isMobile && (
          <div className={styles.filters}>
            <DropdownPopover
              isMobile={isMobile}
              items={{
                [SORT_OPTIONS.POPULARITY]: intl.formatMessage(
                  localizeMessages.popularity
                ),
                [SORT_OPTIONS.TITLE_ASC]: intl.formatMessage(
                  localizeMessages.asc
                ),
              }}
              onChange={this.handleChangeSorting}
              value={sortBy}
            />
          </div>
        )}
        {!isMobile && searchTerm.length > 0 && (
          <button className="secondary" onClick={this.handleClear}>
            <FormattedMessage
              defaultMessage="Clear results"
              id="djcity.records.tag.button.clear"
            />
          </button>
        )}
        <div className={styles.tableContainer}>
          {filteredTags.map(item => {
            return (
              <Link key={item.id} to={`/genre/${item.slug}`}>
                <div className={styles.row}>
                  <TextStyle className={styles.tagText}>{item.name}</TextStyle>
                  <FontAwesomeIcon icon={['fas', 'arrow-right']} size="1x" />
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    tags: state.tags.tags,
    isLoadingTags: state.tags.isLoading,
    isMobile: isMobileSelector(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTags: bindActionCreators(tagActions, dispatch).fetchTags,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(TagSearch))
)

import { Link } from 'react-router-dom'
import React from 'react'

export function makeSongUrl(song) {
  return `/records/${song.rid +
    '-' +
    encodeURIComponent(
      (song.title + '-' + song.artist)
        .replace(/ /g, '-')
        .replace(/\./g, '')
        .replace(/-+/g, '-')
    )}`
}

export function buildArtistURL(q) {
  return (
    '/artist/' + encodeURIComponent(q.replace(/ /g, '--').replace(/\./g, '·'))
  )
}

export function linkifyArtistNames(song, options = {}) {
  let artists = [song.artist, song.featuring]
    .filter(Boolean)
    .join(' ')
    .replace(
      /( \(prod\. )|( \(ft\. )|(; prod\. )|( & )|( vs )|( \(.+?\))|(\) ?)|(, )/gi,
      '</a>$&<a>'
    )
    .replace('<a></a>', '')
    .replace(/\)<a>$/gi, ')')
    .replace(/([^)])$/gi, '$&</a>')
  artists = '<a>' + artists
  artists = artists
    .split(/(<a>.+?<\/a>)/)
    .filter(Boolean)
    .map(str => {
      const artistName = str.match(/^<a>(.+)<\/a>$/)
      return artistName ? (
        <Link
          key={song.rid + artistName[1]}
          target={options.newtab ? '_blank' : null}
          title={artistName[1]}
          to={buildArtistURL(artistName[1])}
        >
          {artistName[1]}
        </Link>
      ) : (
        str
      )
    })

  return artists
}

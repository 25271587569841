import { TextStyle } from 'spa/components/typography/text_style'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

const ActionConfirm = ({
  primaryAction,
  secondaryAction,
  title = '',
  subtitle = '',
  primaryActionText,
  secondaryActionText,
}) => {
  return (
    <div className={classNames(styles.center, styles.questionTextWrapper)}>
      <div className={styles.header}>
        <TextStyle className={styles.headerTitle} uppercase>
          {title}
        </TextStyle>
        <TextStyle color="grey">{subtitle}</TextStyle>
      </div>
      <button onClick={primaryAction}>{primaryActionText}</button>
      {secondaryAction && (
        <button className="secondary" onClick={secondaryAction}>
          {secondaryActionText}
        </button>
      )}
    </div>
  )
}

ActionConfirm.propTypes = {
  primaryAction: PropTypes.func,
  primaryActionText: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryActionText: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

export default ActionConfirm

import { defineMessages } from 'react-intl'

export const seoMessages = defineMessages({
  defaultTitle: {
    id: 'djcity.common.tab.title',
    defaultMessage: 'DJcity | DJ Record Pool and Music Industry News',
  },
  defaultDescription: {
    id: 'djcity.common.meta.description',
    defaultMessage:
      'Get all the tracks you need with access to 150+ genres, optimized for the top DJ software. DJcity is the leading music discovery & delivery platform for DJs around the world.',
  },
  defaultH1: {
    id: 'djcity.common.meta.h1',
    defaultMessage: 'Ultimate DJ Record Pool for DJ Music',
  },
  marketingTitle: {
    id: 'djcity.common.seo.marketing.title',
    defaultMessage: 'DJ Record Pool: Access Premium DJ Music | DJcity',
  },
  marketingDescription: {
    id: 'djcity.common.seo.marketing.description',
    defaultMessage:
      'Discover the ultimate DJ record pool with access to high-quality DJ music and exclusive tracks. Join DJcity for your DJ music pool needs today.',
  },
  marketingH1: {
    id: 'djcity.common.seo.marketing.h1',
    defaultMessage: 'Your Ultimate DJ Record Pool for Premium DJ Music',
  },
  homeTitle: {
    id: 'djcity.common.seo.home.title',
    defaultMessage:
      'DJcity Record Pool: Exclusive DJ Music & Remixes for Professionals',
  },
  homeDescription: {
    id: 'djcity.common.seo.home.description',
    defaultMessage:
      'DJcity record pool for exclusive DJ music, high-quality tracks, and remixes. Get the latest music for your DJ sets today.',
  },
  homeH1: {
    id: 'djcity.common.seo.home.h1',
    defaultMessage: 'Exclusive DJ Record Pool',
  },
  newReleasesTitle: {
    id: 'djcity.common.seo.newReleases.title',
    defaultMessage: 'New Music Releases | Fresh Tracks for DJs | DJcity',
  },
  newReleasesDescription: {
    id: 'djcity.common.seo.newReleases.description',
    defaultMessage:
      'Stay ahead with DJcity’s new music releases. Explore the freshest tracks and new music added daily for your DJ sets.',
  },
  newReleasesH1: {
    id: 'djcity.common.seo.newReleases.h1',
    defaultMessage: 'Latest New Music Releases',
  },
  mostPopularTitle: {
    id: 'djcity.common.seo.mostPopular.title',
    defaultMessage: 'Popular Music & Songs for DJs | DJcity’s Top Picks',
  },
  mostPopularDescription: {
    id: 'djcity.common.seo.mostPopular.description',
    defaultMessage:
      'Browse DJcity’s collection of popular music and songs. Access trending tracks and must-have hits for your DJ playlists.',
  },
  mostPopularH1: {
    id: 'djcity.common.seo.mostPopular.h1',
    defaultMessage: 'Most Popular Music & Songs',
  },
  exclusivesTitle: {
    id: 'djcity.common.seo.exclusives.title',
    defaultMessage: 'Exclusive DJ Mashups & Remixes | DJcity',
  },
  exclusivesDescription: {
    id: 'djcity.common.seo.exclusives.description',
    defaultMessage:
      'Access exclusive DJ mashups and remixes only on DJcity. Enhance your sets with unique, high-energy tracks from top remixers.',
  },
  exclusivesH1: {
    id: 'djcity.common.seo.exclusives.h1',
    defaultMessage: 'Exclusive DJ Edits, Mashups & Remixes',
  },
  playlistsMonthlyTitle: {
    id: 'djcity.common.seo.playlistsMonthly.title',
    defaultMessage: 'Curated DJ Playlists for Every Month | DJcity',
  },
  playlistsMonthlyDescription: {
    id: 'djcity.common.seo.playlistsMonthly.description',
    defaultMessage:
      'Discover monthly DJ playlists on DJcity. Find expertly curated playlists to inspire and energize your DJ sets.',
  },
  playlistsMonthlyH1: {
    id: 'djcity.common.seo.playlistsMonthly.h1',
    defaultMessage: 'Monthly DJ Playlists for Your Sets',
  },
  monthlyChartsTitle: {
    id: 'djcity.common.seo.monthlyCharts.title',
    defaultMessage: 'Top Music Charts for DJs | Monthly Charts on DJcity',
  },
  monthlyChartsDescription: {
    id: 'djcity.common.seo.monthlyCharts.description',
    defaultMessage:
      'Stay updated with DJcity’s monthly music charts. Access top music charts featuring the latest trending tracks for DJs.',
  },
  monthlyChartsH1: {
    id: 'djcity.common.seo.monthlyCharts.h1',
    defaultMessage: 'Latest Monthly Music Charts',
  },
  genresTitle: {
    id: 'djcity.common.seo.genres.title',
    defaultMessage: 'Explore Music Genres & Lists for DJs | DJcity',
  },
  genresDescription: {
    id: 'djcity.common.seo.genres.description',
    defaultMessage:
      'Dive into DJcity’s extensive music genres list. Find tracks from all your favorite music genres in one place.',
  },
  genresH1: {
    id: 'djcity.common.seo.genres.h1',
    defaultMessage: 'Explore Diverse Music Genres',
  },
  remixersTitle: {
    id: 'djcity.common.seo.remixers.title',
    defaultMessage: 'Top Remixers for DJs | Exclusive Remixes on DJcity',
  },
  remixersDescription: {
    id: 'djcity.common.seo.remixers.description',
    defaultMessage:
      'Explore tracks from the best remixers on DJcity. Enhance your sets with exclusive remixes by top artists and producers.',
  },
  remixersH1: {
    id: 'djcity.common.seo.remixers.h1',
    defaultMessage: 'Top Remixers on DJCity',
  },
  trackUpdatesTitle: {
    id: 'djcity.common.seo.trackUpdates.title',
    defaultMessage: 'Latest Track Updates for DJs | Stay Current with DJcity',
  },
  trackUpdatesDescription: {
    id: 'djcity.common.seo.trackUpdates.description',
    defaultMessage:
      'Stay up-to-date with DJcity’s latest track updates. Access new and trending tracks to keep your DJ sets fresh.',
  },
  trackUpdatesH1: {
    id: 'djcity.common.seo.trackUpdates.h1',
    defaultMessage: 'Latest Track on DJ City',
  },
  globalTitle: {
    id: 'djcity.common.seo.global.title',
    defaultMessage: 'World Music & Global Tracks for DJs | DJcity',
  },
  globalDescription: {
    id: 'djcity.common.seo.global.description',
    defaultMessage:
      'Discover DJcity’s collection of world music and global tracks. Expand your library with diverse music from around the globe.',
  },
  globalH1: {
    id: 'djcity.common.seo.global.h1',
    defaultMessage: 'World & Global Music',
  },
})

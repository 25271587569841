export const TRACKLIST = {
  NEW_RELEASES: 'New Releases',
  ARTISTS: 'Artists',
  EXCLUSIVES: 'Exclusives',
  GLOBAL: 'Global',
  HOTBOX: 'Hotbox',
  PLAYLISTS: 'Playlists',
  REMIXERS: 'Remixers',
  GENRES: 'Genres',
  TOP_50: 'Top 50',
  RELATED: 'Track Details - Related',
  RELATED_NEW_STACKED_STYLE: 'Track Details - Recommended (Stacked Style)',
  SEARCH: 'Search',
  HISTORY: 'History',
  UPDATES: 'Updates',
  CRATE: 'Crate',
  STATISTICS: 'Statistics',
}

export const seoData = {
  '/': 'marketing',
  '/home': 'home',
  '/new-releases': 'newReleases',
  '/most-popular': 'mostPopular',
  '/exclusives': 'exclusives',
  '/playlists/monthly': 'playlistsMonthly',
  '/monthly-charts': 'monthlyCharts',
  '/genres': 'genres',
  '/remixers': 'remixers',
  '/track-updates': 'trackUpdates',
  '/global': 'global',
}

import { defineMessages, FormattedHTMLMessage, injectIntl } from 'react-intl'
import ActionConfirm from 'spa/clear_confirm/clear_confirm'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styles from './styles'

const localizedMessages = defineMessages({
  notLoading: {
    id: 'djcity.common.not_loading',
    defaultMessage: 'Site not loading? Click below.',
  },
  clearCache: {
    id: 'djcity.common.clear_cache',
    defaultMessage: 'CLEAR CACHE & RELOAD',
  },
  helpTips: {
    id: 'djcity.common.help_tips',
    defaultMessage: `Still having problems? Read our FAQ articles.
      <br>
      <a target="_blank" href="https://support.djcity.com/hc/en-us/articles/29531057703835-Why-am-I-seeing-an-ERROR-message-on-the-site">
        Why am I seeing an ERROR message on the site?
      </a>
      <br>
      <a target="_blank" href="https://support.djcity.com/hc/en-us/articles/29531215960603-Why-is-the-site-not-loading">
        Why is the site not loading?
      </a>`,
  },
  modalTitle: {
    id: 'djcity.common.clear_cache.title',
    defaultMessage: 'Are you sure?',
  },
  modalSubtitle: {
    id: 'djcity.common.clear_cache.subtitle',
    defaultMessage: 'You will be logged out of DJcity',
  },
  modalConfirm: {
    id: 'djcity.common.clear_cache.confirm',
    defaultMessage: 'Yes',
  },
  modalCancel: {
    id: 'djcity.common.clear_cache.cancel',
    defaultMessage: 'No',
  },
})

export const ClearCacheButton = injectIntl(({ isLoadingPage, intl }) => {
  const [clearCacheModal, setClearCacheModal] = useState(false)

  const clearCache = () => {
    localStorage.clear()
    location.reload()
  }

  return (
    <>
      <div className={styles.help}>
        {isLoadingPage ? (
          <span>{intl.formatMessage(localizedMessages.notLoading)}</span>
        ) : null}
        <div>
          <button onClick={() => setClearCacheModal(true)}>
            {intl.formatMessage(localizedMessages.clearCache)}
          </button>
        </div>
        <FormattedHTMLMessage {...localizedMessages.helpTips} />
      </div>
      <Modal className={styles.modal} isOpen={clearCacheModal}>
        <ActionConfirm
          primaryAction={clearCache}
          primaryActionText={intl.formatMessage(localizedMessages.modalConfirm)}
          secondaryAction={() => setClearCacheModal(false)}
          secondaryActionText={intl.formatMessage(
            localizedMessages.modalCancel
          )}
          subtitle={intl.formatMessage(localizedMessages.modalSubtitle)}
          title={intl.formatMessage(localizedMessages.modalTitle)}
        />
      </Modal>
    </>
  )
})

ClearCacheButton.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  isLoadingPage: PropTypes.bool,
}

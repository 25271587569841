import { defineMessages } from 'react-intl'

export default defineMessages({
  djcityTitle: {
    id: 'djcity.common.tab.title',
    defaultMessage: 'DJcity | DJ Record Pool and Music Industry News',
  },
  about: {
    id: 'djcity.common.pagetitle.about',
    defaultMessage: 'DJcity | DJ Record Pool and Music Industry News',
  },
  apps: {
    id: 'djcity.common.pagetitle.apps',
    defaultMessage: 'Apps | DJcity',
  },
  faq: {
    id: 'djcity.common.pagetitle.faq',
    defaultMessage: 'FAQ | DJcity',
  },
  login: {
    id: 'djcity.common.pagetitle.login',
    defaultMessage: 'Login | DJcity',
  },
  news: {
    id: 'djcity.common.pagetitle.news',
    defaultMessage: 'News | DJcity',
  },
  playlists: {
    id: 'djcity.common.pagetitle.playlists',
    defaultMessage: 'Playlists | DJcity',
  },
  account: {
    id: 'djcity.common.pagetitle.account',
    defaultMessage: 'Account | DJcity',
  },
  track_updates: {
    id: 'djcity.common.pagetitle.track_updates',
    defaultMessage: 'Track Updates | DJcity',
  },
  recommendations: {
    id: 'djcity.common.pagetitle.recommendations',
    defaultMessage: 'Your Recommendations | DJcity',
  },
  error: {
    id: 'djcity.common.pagetitle.error',
    defaultMessage: 'Whoops, something went wrong | DJcity',
  },
  forgot_password: {
    id: 'djcity.common.pagetitle.forgot_password',
    defaultMessage: 'Forgot Password | DJcity',
  },
  update_address: {
    id: 'djcity.common.pagetitle.update_address',
    defaultMessage: 'Update Address | DJcity',
  },
  downloads: {
    id: 'djcity.common.pagetitle.downloads',
    defaultMessage: 'Downloads | DJcity',
  },
  guide: {
    id: 'djcity.common.pagetitle.guide',
    defaultMessage: 'Guide | DJcity',
  },
  payment: {
    id: 'djcity.common.pagetitle.payment',
    defaultMessage: 'Payment | DJcity',
  },
  'privacy-policy': {
    id: 'djcity.common.pagetitle.privacy_policy',
    defaultMessage: 'Privacy Policy | DJcity',
  },
  return_policy: {
    id: 'djcity.common.pagetitle.return_policy',
    defaultMessage: 'Return Policy | DJcity',
  },
  subscription: {
    id: 'djcity.common.pagetitle.subscription',
    defaultMessage: 'Subscription | DJcity',
  },
  search: {
    id: 'djcity.common.pagetitle.search',
    defaultMessage: 'Search | DJcity',
  },
  'terms-of-use': {
    id: 'djcity.common.pagetitle.terms_of_use',
    defaultMessage: 'Terms of use | DJcity',
  },
  reset_password: {
    id: 'djcity.common.pagetitle.reset_password',
    defaultMessage: 'Reset Password | DJcity',
  },
})

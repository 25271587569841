import { injectIntl } from 'react-intl'
import { seoMessages } from '../../app_wrapper/localizedMessages'
import NewReleasesTable from 'spa/components/new_releases_table'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const NewReleases = ({ intl }) => (
  <div className={styles.base}>
    <NewReleasesTable
      subHeading={intl.formatMessage(seoMessages.newReleasesH1)}
    />
  </div>
)

NewReleases.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(NewReleases)

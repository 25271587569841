import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles'

export const Heading = ({
  pull,
  className,
  color,
  headingVariant = 'h1',
  ...rest
}) => {
  const cls = classNames(
    {
      [styles.heading]: headingVariant === 'h1',
      [styles.disguisedH2]: headingVariant === 'disguisedH2',
      [styles.disguisedH1]: headingVariant === 'disguisedH1',
    },
    {
      [styles.white]: color === 'white',
      [styles.black]: color === 'black',
    },
    className,
    pull ? styles.pull : null
  )
  return headingVariant === 'disguisedH2' ? (
    <h2 className={cls} {...rest} />
  ) : headingVariant === 'disguisedH1' ? (
    <h1 className={cls} {...rest} />
  ) : (
    <h1 className={cls} {...rest} />
  )
}

Heading.displayName = 'Heading'

Heading.propTypes = {
  className: PropTypes.string,
  /** Default behavior is to inherit color */
  color: PropTypes.oneOf(['white', 'black']),
  /** When true, renders as h2 instead of h1 default is h1 */
  headingVariant: PropTypes.oneOf(['h1', 'disguisedH2', 'disguisedH1']),
  pull: PropTypes.bool,
}

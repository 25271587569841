import { BpmFilterPanel, KeyFilterPanel, TypeFilterPanel } from '.'
import { defaultFilters } from 'spa/api/songs'
import { FilterButtonIcon } from './filter_button_icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { Popover } from 'spa/components/popover'
import { standardFiltersShape } from 'spa/api/songs'
import { TextStyle } from 'spa/components/typography'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import PropTypes, { string } from 'prop-types'
import React from 'react'

import styles from './styles'

const acceptedKeys = ['bpmgt', 'bpmlt', 'types', 'keys', 'ctypes']

export class FilterPopover extends React.PureComponent {
  static propTypes = {
    className: string,
    filters: PropTypes.shape(standardFiltersShape),
    isMobile: PropTypes.bool,
    onChange: PropTypes.func,
  }

  handleGenreTagChange = tags => {
    const { filters, onChange } = this.props
    if (onChange) {
      onChange({ ...filters, tags, page: 1 })
    }
  }

  handleTypeChange = (variant, value) => {
    const { filters, onChange } = this.props
    if (onChange) {
      onChange({ ...filters, [variant]: value, page: 1 })
    }
  }

  handleBpmChange = ({ bpmlt, bpmgt }) => {
    const { filters, onChange } = this.props
    if (onChange) {
      onChange({ ...filters, bpmlt, bpmgt, page: 1 })
    }
  }

  handleKeysChange = keys => {
    const { filters, onChange } = this.props
    if (onChange) {
      onChange({ ...filters, keys, page: 1 })
    }
  }

  clearFilters = () => {
    const { filters, onChange } = this.props
    if (onChange) {
      onChange({ ...filters, ...pick(defaultFilters, acceptedKeys), page: 1 })
    }
  }

  render() {
    const { filters, className, isMobile, ...rest } = this.props
    const isFiltered = !isEqual(
      pick(defaultFilters, acceptedKeys),
      pick(filters, acceptedKeys)
    )

    const ClearFilterButton = () =>
      isFiltered && (
        <button
          className={classNames('important', styles.clearFilterButton)}
          onClick={this.clearFilters}
          style={isMobile ? { margin: 'auto' } : undefined}
        >
          <TextStyle color="white" variant="bold">
            <FormattedMessage
              defaultMessage="Clear Filters"
              id="djcity.common.clearFilters"
            />
          </TextStyle>
        </button>
      )

    return (
      <Popover
        className={className}
        closeButton={isMobile}
        closeOnClick={false}
        closeOnScroll={false}
        contentClassName={styles.filterPopoverContent}
        renderTrigger={({ handleClick, open, ref }) => (
          <button
            className={classNames({
              popoverTrigger: !open,
              popoverTriggerOpen: open,
              [styles.filtered]: isFiltered,
              mobile: isMobile,
            })}
            onClick={handleClick}
            ref={ref}
          >
            {isMobile ? (
              <FontAwesomeIcon icon="sliders-h" />
            ) : (
              <>
                <FormattedMessage
                  defaultMessage="Filter By"
                  id="djcity.common.filterBy"
                />
                &nbsp;
                <FilterButtonIcon isOpen={open} />
              </>
            )}
          </button>
        )}
        {...rest}
      >
        {isMobile && <ClearFilterButton />}
        <div className={styles.filterPopoverWrapper}>
          <TypeFilterPanel
            ctypes={filters.ctypes}
            ctypesClass={styles.ctypeFilterPanel}
            onChange={this.handleTypeChange}
            types={filters.types}
            typesClass={styles.typeFilterPanel}
          />
          <BpmFilterPanel
            bpmgt={filters.bpmgt}
            bpmlt={filters.bpmlt}
            className={styles.bpmFilterPanel}
            onChange={this.handleBpmChange}
          />
          <KeyFilterPanel
            className={styles.keyFilterPanel}
            keys={filters.keys}
            onChange={this.handleKeysChange}
          />
        </div>
        {!isMobile && <ClearFilterButton />}
      </Popover>
    )
  }
}

import { AccountDisplay, AccountInvoiceDisplay } from '../../components/account'
import { AddressDisplay } from '../../components/pages/address'
import { ApplicationRoute } from '../../../routes/applicationRoute'
import { AppsDisplay } from '../../components/pages/apps'
import { ArtistsDisplay } from '../../components/pages/artists'
import { AssetDownloadDisplay } from '../../components/pages/asset_download'
import { CookiesDisplay } from '../../components/pages/cookies'
import { ExclusivesDisplay } from '../../components/pages/exclusives'
import { ForgotPasswordDisplay } from '../../components/forgot_password_display'
import { FreePackCampaignDisplay } from '../../components/free_pack_campaign'
import { GlobalDisplay } from '../../components/pages/global'
import { GuideDisplay } from '../../components/pages/guide'
import { HomeDisplay, HomeOnboardingDisplay } from '../../components/pages/home'
import { HotBoxDisplay } from '../../components/pages/hot_box'
import { LOCALIZED_ROUTES } from '../../../locale_data/config'
import { LocalizedRoute } from '../../../routes/localizedRoutes'
import { loggedInSelector, onboardingSelector } from '../../selectors/users'
import { LoginDisplay } from '../../components/login_display'
import { MarketingDisplay } from '../../components/pages/marketing'
import { NewReleasesDisplay } from '../../components/pages/new_releases'
import { NewsDisplay } from '../../components/pages/news'
import { PaymentConfirmationDisplay } from '../../components/payment_confirmation'
import { PaymentDetailsDisplay } from '../../components/payment_details'
import { PaymentDisplay } from '../../components/pages/payment'
import { PlaylistsDisplay } from '../../components/pages/playlists'
import { PostRoutesDisplay } from '../../components/pages/posts'
import { Redirect, Switch } from 'react-router-dom'
import { RemixersDisplay } from '../../components/pages/remixers'
import { RemixerSearchDisplay } from '../../components/pages/remixer_search'
import { ResetPasswordDisplay } from '../../components/reset_password_display'
import { ReturnPolicyDisplay } from '../../components/pages/return_policy'
import { SearchSongsDisplay } from 'spa/components/search_songs'
import { SongDetailsDisplay } from 'spa/components/song_details'
import { StatisticsDisplay } from '../../components/pages/statistics'
import { SubscriptionDisplay } from 'spa/components/pages/subscription'
import { TagsDisplay } from 'spa/components/pages/tags'
import { TagSearchDisplay } from 'spa/components/pages/tag_search'
import { TopDownloadsDisplay } from 'spa/components/pages/top_downloads'
import { UpdatedSongsDisplay } from 'spa/components/pages/updated_songs'
import { useSelector } from 'react-redux'
import { WelcomeBackCampaignDisplay } from '../../components/welcome-back-campaign'
import React from 'react'

// eslint-disable-next-line react/display-name
export default () => {
  const isLoggedIn = useSelector(loggedInSelector)
  const hasOnboarding = useSelector(onboardingSelector)

  return (
    <Switch>
      <Redirect
        from="/digital/(.*?)(---*):trackId(\d+)(.*)"
        to="/records/:trackId"
      />
      <Redirect from="/djcity-downloader-readme" to="/guide" />
      <Redirect from="/trends" to="/playlists" />
      <Redirect from="/charts" to="/monthly-charts" />
      <Redirect exact from="/(playlist|playlists)" to="/playlists/monthly" />
      <ApplicationRoute
        path="/faq"
        render={() => {
          window.location = 'https://support.djcity.com/hc/en-us'
          return null
        }}
      />
      <Redirect exact from="/(about|aboutus)" to={isLoggedIn ? '/home' : '/'} />

      <ApplicationRoute
        exact
        path="/"
        render={isLoggedIn ? () => <Redirect to="/home" /> : MarketingDisplay}
      />

      <ApplicationRoute
        exact
        path="/home"
        render={hasOnboarding ? HomeOnboardingDisplay : HomeDisplay}
      />
      <ApplicationRoute
        exact
        path="/new-releases"
        render={NewReleasesDisplay}
      />
      <ApplicationRoute exact path="/most-popular" render={HotBoxDisplay} />
      <ApplicationRoute exact path="/exclusives" render={ExclusivesDisplay} />
      <ApplicationRoute
        exact
        path="/(playlist|playlists)/:groupSlug/:listSlug?"
        render={PlaylistsDisplay}
      />
      <ApplicationRoute exact path="/genre/:id" render={TagsDisplay} />
      <ApplicationRoute exact path="/genres" render={TagSearchDisplay} />
      <ApplicationRoute exact path="/remixers" render={RemixerSearchDisplay} />
      <ApplicationRoute exact path="/global" render={GlobalDisplay} />
      <ApplicationRoute
        exact
        path="/(remixer|remixers)/:slug"
        render={RemixersDisplay}
      />
      <ApplicationRoute
        exact
        path="/(artist|artists)"
        render={ArtistsDisplay}
      />
      <ApplicationRoute
        exact
        path="/(artist|artists)/:slug"
        render={ArtistsDisplay}
      />
      <ApplicationRoute
        exact
        path="/monthly-charts"
        render={TopDownloadsDisplay}
      />
      <ApplicationRoute exact path="/records/:id" render={SongDetailsDisplay} />
      <ApplicationRoute exact path="/search" render={SearchSongsDisplay} />
      <ApplicationRoute exact path="/apps" render={AppsDisplay} />
      <ApplicationRoute
        exact
        path="/(about|aboutus)"
        render={MarketingDisplay}
      />
      <ApplicationRoute
        exact
        path="/offer/:offerName"
        render={MarketingDisplay}
      />
      <ApplicationRoute exact path="/guide" render={GuideDisplay} />
      <ApplicationRoute exact path="/cookies" render={CookiesDisplay} />
      <ApplicationRoute
        exact
        path="/return-policy"
        render={ReturnPolicyDisplay}
      />
      <ApplicationRoute exact path="/news" render={NewsDisplay} />
      <LocalizedRoute
        path={`/:locale(${Object.keys(LOCALIZED_ROUTES).join('|')})`}
        to="/"
      />
      <ApplicationRoute
        exact
        path="/payment-details"
        render={PaymentDetailsDisplay}
        requireAuth
      />
      <ApplicationRoute
        exact
        path="/payment-confirmation"
        render={PaymentConfirmationDisplay}
        requireAuth
      />
      <ApplicationRoute
        exact
        path="/payment-update"
        render={PaymentDisplay}
        requireAuth
      />
      <ApplicationRoute
        exact
        path="/update-address"
        render={AddressDisplay}
        requireAuth
      />
      <ApplicationRoute
        exact
        path="/update-password"
        render={ResetPasswordDisplay}
      />
      <ApplicationRoute
        exact
        path="/forgot-password"
        render={ForgotPasswordDisplay}
      />
      <ApplicationRoute exact path="/login" render={LoginDisplay} />
      <ApplicationRoute
        exact
        path="/free-download-pack"
        render={FreePackCampaignDisplay}
      />
      <ApplicationRoute
        exact
        path="/asset-download"
        render={AssetDownloadDisplay}
        requireAuth
      />
      <ApplicationRoute
        exact
        path="/welcome-back"
        render={WelcomeBackCampaignDisplay}
      />
      <ApplicationRoute
        exact
        path="/track-updates"
        render={UpdatedSongsDisplay}
      />
      <ApplicationRoute
        component={AccountInvoiceDisplay}
        exact
        path="/account/billing/invoices/:id"
        requireAuth
      />
      <ApplicationRoute
        exact
        path="/account/change-subscription"
        render={SubscriptionDisplay}
        requireAuth
      />
      <ApplicationRoute path="/account" render={AccountDisplay} requireAuth />
      <ApplicationRoute
        exact
        path="/statistics/:guid"
        render={StatisticsDisplay}
      />
      <ApplicationRoute path="*" render={PostRoutesDisplay} />
    </Switch>
  )
}

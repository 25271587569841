import { clearRemixers, fetchAllRemixers } from 'spa/action_creators/remixers'
import {
  defaultRemixersPagination,
  defaultRemixersParams,
  filterParsers,
  filterStringifiers,
} from '../../../api/songs'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Heading, TextStyle } from '../../typography'
import { isMobileSelector } from '../../../selectors/device'
import { Knotch } from '../../knotch'
import { Link } from '../../link'
import { Loading } from '../../loading'
import { NoImage } from '../../missing_image'
import { NoRecordsLabel } from '../../no_records_label'
import { SearchBox } from 'spa/components/search_box'
import { seoMessages } from '../../app_wrapper/localizedMessages'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryParameters } from '../../../hooks/use_query_parameters'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles'

const localizeMessages = defineMessages({
  placeholder: {
    id: 'djcity.common.remixer.search.placeholder',
    defaultMessage: 'Search remixer...',
  },
  allRemixers: {
    id: 'djcity.common.remixer.title.all_remixers',
    defaultMessage: 'ALL REMIXERS',
  },
  featuredRemixers: {
    id: 'djcity.common.remixers.search.title',
    defaultMessage: 'FEATURED REMIXERS',
  },
})

const RemixerSearch = ({ intl }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.remixers.isFetchingAll)
  const remixers = useSelector(state => state.remixers.allRemixers)
  const isMobile = useSelector(isMobileSelector)
  const [searchTerm, setSearchTerm] = useState('')
  const [queryParams, setQueryParams] = useQueryParameters(
    {
      ...defaultRemixersParams,
      ...defaultRemixersPagination,
    },
    {
      parsers: filterParsers,
      stringifiers: filterStringifiers,
    }
  )

  const debouncedSearch = useCallback(
    debounce(
      searchTerm =>
        setQueryParams({
          ...queryParams,
          name: searchTerm,
          onlyFeatured: !searchTerm,
        }),
      100
    ),
    [queryParams, setQueryParams]
  )

  const handleClear = () => {
    setQueryParams({
      ...defaultRemixersParams,
      ...defaultRemixersPagination,
    })
    setSearchTerm('')
  }

  const handleSearchChange = e => {
    setSearchTerm(e.target.value)
    debouncedSearch(e.target.value)
  }

  useEffect(() => {
    dispatch(
      fetchAllRemixers({
        name: queryParams.name,
        page: queryParams.page,
        pageSize: queryParams.pageSize,
        sortBy: queryParams.sortBy,
        onlyFeatured: queryParams.onlyFeatured,
      })
    )
    return () => dispatch(clearRemixers())
  }, [queryParams, dispatch])

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage
            defaultMessage="REMIXERS"
            id="djcity.common.remixers.search.uptitle"
          />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading headingVariant="disguisedH2">
            {queryParams.name
              ? intl.formatMessage(localizeMessages.allRemixers)
              : intl.formatMessage(localizeMessages.featuredRemixers)}
          </Heading>
        </div>
        <Heading headingVariant="disguisedH1">
          {intl.formatMessage(seoMessages.remixersH1)}
        </Heading>
        <Knotch className={styles.knotch} size="big" />
      </div>
      <SearchBox
        className={styles.tagSearch}
        onChange={handleSearchChange}
        placeholder={intl.formatMessage(localizeMessages.placeholder)}
        reset={isMobile && handleClear}
        value={searchTerm}
      />
      {!isMobile && queryParams.name && (
        <button className="secondary" onClick={handleClear}>
          <FormattedMessage
            defaultMessage="Clear results"
            id="djcity.records.tag.button.clear"
          />
        </button>
      )}
      {!isFetching && remixers ? (
        <div
          className={
            queryParams.name ? styles.tableContainer : styles.imageContainer
          }
        >
          {remixers.map(item => {
            return !queryParams.name ? (
              <div className={styles.row} key={item.id}>
                <Link key={item.id} to={`/remixer/${item.slug}`}>
                  <div
                    className={
                      item.image_url
                        ? styles.remixerImage
                        : styles.notRemixerImage
                    }
                  >
                    {item.image_url ? (
                      <img alt={item.name} src={item.image_url} />
                    ) : (
                      <NoImage />
                    )}
                  </div>
                  <div className={styles.title}>
                    <TextStyle className={styles.tagText}>
                      {item.name}
                    </TextStyle>
                    <TextStyle variant="label">{item.country}</TextStyle>
                  </div>
                </Link>
              </div>
            ) : (
              <Link key={item.id} to={`/remixer/${item.slug}`}>
                <div className={styles.listRow}>
                  <TextStyle className={styles.tagText}>{item.name}</TextStyle>
                  <FontAwesomeIcon icon={['fas', 'arrow-right']} size="1x" />
                </div>
              </Link>
            )
          })}
          {remixers && remixers.length === 0 && <NoRecordsLabel />}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

RemixerSearch.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(RemixerSearch)

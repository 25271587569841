import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import {
  clearGlobalList,
  fetchGlobalSongs,
} from '../../../action_creators/global_songs'
import { DEFAULT_DATE_OPTION, generateQueryParams } from '../../../shared/utils'
import {
  defaultGlobalFilters,
  defaultGlobalPagination,
  filterParsers,
  filterStringifiers,
} from '../../../api/songs'
import { DropdownPopover } from 'spa/components/filters/dropdown_popover'
import { FilterPopover, SORT_OPTIONS } from '../../filters'
import { GlobalFilterPanel } from '../../filters/global_filter_panel'
import {
  globalSongsSelector,
  globalSongsSelectorGrouped,
} from '../../../selectors/songs'
import { Heading } from 'spa/components/typography'
import { isMobileSelector } from '../../../selectors/device'
import { Knotch } from '../../knotch'
import { Loading } from '../../loading'
import { localeSelector, loggedInSelector } from '../../../selectors/users'
import { LOCALIZED_MESSAGES } from '../../table/messages'
import { NoRecordsLabel } from '../../no_records_label'
import { Pagination } from '../../pagination'
import { seoMessages } from '../../app_wrapper/localizedMessages'
import { SongTable } from '../../table'
import { TextStyle } from '../../typography'
import { TogglePanel } from '../../toggle_panel'
import { TRACKLIST } from '../../../shared/constants'
import { useDispatch, useSelector } from 'react-redux'
import { usePaginationUrl } from '../../../hooks/use_pagination_url'
import { useQueryParameters } from '../../../hooks/use_query_parameters'
import styles from './styles'

const Global = ({ intl }) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.globalSongs.isFetching)
  const pageCount = useSelector(state => state.globalSongs.pagination.pageCount)
  const isLoadingMore = useSelector(state => state.songs.isLoading)
  const isLoggedIn = useSelector(loggedInSelector)
  const locale = useSelector(localeSelector)
  const isMobile = useSelector(isMobileSelector)

  const [queryParams, setQueryParams] = useQueryParameters(
    {
      ...defaultGlobalFilters,
      ...defaultGlobalPagination,
    },
    {
      parsers: filterParsers,
      stringifiers: filterStringifiers,
    }
  )

  const songs = useSelector(
    queryParams.sortBy === SORT_OPTIONS.POPULARITY
      ? globalSongsSelector
      : globalSongsSelectorGrouped
  )
  const buildPaginationUrl = usePaginationUrl(queryParams)

  const handleChangeSort = sortBy => setQueryParams({ ...queryParams, sortBy })

  // Resets and loads the song list
  useEffect(() => {
    dispatch(fetchGlobalSongs(generateQueryParams({ locale, queryParams })))

    return () => dispatch(clearGlobalList())
  }, [locale, queryParams, isLoggedIn, dispatch])

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <TextStyle uppercase variant="extra-bold">
          <FormattedMessage
            defaultMessage="Record Pool"
            id="djcity.common.record_pool"
          />
        </TextStyle>
        <div className={styles.headingWrapper}>
          <Heading headingVariant="disguisedH2">
            <FormattedMessage
              defaultMessage="Global Music"
              id="djcity.common.global_songs"
            />
          </Heading>
          {!isMobile && (
            <div>
              <TogglePanel
                items={[
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.popularity),
                    value: SORT_OPTIONS.POPULARITY,
                  },
                  {
                    label: intl.formatMessage(LOCALIZED_MESSAGES.date),
                    value: SORT_OPTIONS.DATE_DESC,
                  },
                ]}
                onChange={handleChangeSort}
                showSeparator
                value={queryParams.sortBy}
              />
              <FilterPopover filters={queryParams} onChange={setQueryParams} />
            </div>
          )}
        </div>
        <Heading headingVariant="disguisedH1">
          {intl.formatMessage(seoMessages.globalH1)}
        </Heading>
        <Knotch className={styles.knotch} size="big" />
      </div>
      {isMobile && (
        <div className={styles.filters}>
          <DropdownPopover
            isMobile={isMobile}
            items={{
              [SORT_OPTIONS.POPULARITY]: intl.formatMessage(
                LOCALIZED_MESSAGES.popularity
              ),
              [SORT_OPTIONS.DATE_DESC]: intl.formatMessage(
                LOCALIZED_MESSAGES.date
              ),
            }}
            onChange={handleChangeSort}
            value={queryParams.sortBy}
          />
          <FilterPopover
            filters={queryParams}
            isMobile={isMobile}
            onChange={setQueryParams}
          />
        </div>
      )}
      <GlobalFilterPanel filters={queryParams} onChange={setQueryParams} />
      {isFetching && !songs.length ? (
        <Loading />
      ) : (
        <Pagination
          buildUrl={buildPaginationUrl}
          page={queryParams.page}
          pageCount={pageCount}
        >
          {queryParams.sortBy === SORT_OPTIONS.POPULARITY ? (
            <SongTable
              className={styles.table}
              firstColumnHeaderLabel="Records"
              songs={songs}
              trackList={TRACKLIST.GLOBAL}
            />
          ) : (
            songs.map((group, i) => (
              <SongTable
                className={styles.table}
                firstColumnHeaderLabel={intl.formatDate(
                  new Date(group.releasedate),
                  DEFAULT_DATE_OPTION
                )}
                isLoading={isLoadingMore}
                key={group.releasedate}
                nextSongs={i + 1 === songs.length ? [] : songs[i + 1].songs}
                songs={group.songs}
                trackList={TRACKLIST.GLOBAL}
              />
            ))
          )}
          {songs.length === 0 ? <NoRecordsLabel /> : null}
        </Pagination>
      )}
    </div>
  )
}

Global.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    formatDate: PropTypes.func,
  }).isRequired,
}

export default injectIntl(Global)

import { RECEIVE_TAGS, REQUEST_TAGS } from '../action_constants'

export default function tags(
  state = { isLoading: false, tag: null, tags: null },
  action
) {
  switch (action.type) {
    case REQUEST_TAGS:
      return {
        ...state,
        tag: action.tag,
        isLoading: true,
      }
    case RECEIVE_TAGS:
      return {
        ...state,
        tags: action.tags,
        isLoading: false,
      }
    default:
      return state
  }
}
